import Axios from 'axios'
import { API_ENDPOINT } from '..'
import { SessionBookingSnake } from '../responseTypes/session'
import { ApiResultWithStatus } from '../types'

export const API_SESSIONS_UPCOMING = 'portal/GetUserUpcoming'
export const API_SESSIONS_PLUGIN = 'schedule/sessions-schedule'
export const API_LATEST_STANDALONE = 'schedule/GetLatestStandalone'

type GetUpcomingSessionParams = {
    hostId: number
    memberId: number
    locationId?: number
}

type SessionsPluginResult =
    | {
          tagIds: number[]
          primaryColor: string
          secondaryColor: string
          promoMembershipsText: string
      }
    | undefined

export type StandaloneData = {
    info: {
        name: string
        bio: string
        profileImage: string
        hostId: number
        monthlyPrice: number
        featuredMemberships: {
            id: number
            name: string
        }[]
    }
    hasOnDemandAddon: boolean
    currency: string
    numberOfCourses: number
    sessions: {
        id: number
        date_string: string
        session_name: string
        duration_minutes: number
        level: string
        in_person: boolean | null
        location: string | null
        type: string
    }[]
    wordingCustomizations: {
        teacherTranslationKey: string | null
        sessionTranslationKey: string | null
        businessTranslationKey: string | null
        semesterTranslationKey: string | null
        courseTranslationKey: string | null
    } | null
}

export const getUpcomingSessions = async (params: GetUpcomingSessionParams) =>
    (
        await Axios.get<ApiResultWithStatus<SessionBookingSnake[]>>(`${API_ENDPOINT}/portal/GetUserUpcoming`, {
            params,
        })
    ).data.message

export const getSessionsPlugin = async (hostId: number) =>
    (
        await Axios.get<SessionsPluginResult>(`${API_ENDPOINT}/schedule/sessions-schedule`, {
            params: {
                hostId,
            },
        })
    ).data

export const getLatestStandalone = async (hostUrl: string, timezoneOffset: number) =>
    (
        await Axios.get<{ message: StandaloneData }>(`${API_ENDPOINT}/schedule/GetLatestStandalone`, {
            params: { hostUrl, timezoneOffset, excludeCollections: true },
        })
    ).data.message
