import styled from 'styled-components/macro'
import { useHomeContext } from '../HomeContext'
import { QUERY } from '../../../styling/contants'

export const Header = () => {
    const { data } = useHomeContext()

    return (
        <HeaderContainer>
            <HeaderLogo src={data?.info.profileImage} />
            <HeaderName>{data?.info.name}</HeaderName>
        </HeaderContainer>
    )
}

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @media ${QUERY.belowXs} {
        padding: 0 1rem;
    }
`

const HeaderLogo = styled.div<{ src?: string }>`
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 1rem;
    margin-right: 2rem;
    background-image: url('${({ src }) => src ?? 'https://withribbon.com/images/ribbon.png'}');
    background-size: cover;
    background-position: center;
`

const HeaderName = styled.h1`
    font-family: ${({ theme }) => theme.typography.font.header.family};
    font-size: 1.5rem;
    font-weight: 500;
`
