import { useQuery } from 'react-query'
import { WEBSITE } from '../../api'
import { API_APPOINTMENTS_MENU, getAppointmentsMenu } from '../../api/endpoints/appointments'
import { useLandingLang } from '../../hooks/useLandingLang'
import { Tab } from '../../components/Tab'
import { useHomeContext } from '../HomeContext'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { isOnDemandHidden } from '../../featureFlags'

export const LinkTabs = () => {
    const t = useLandingLang()
    const { data, hostId, selectedContent, setSelectedContent } = useHomeContext()

    const { data: appointmentsMenu } = useQuery([hostId, API_APPOINTMENTS_MENU], () => getAppointmentsMenu(hostId))

    const tabs = useMemo(
        () => [
            <Tab key="tab-1" selected={selectedContent === 'live'} onClick={() => setSelectedContent('live')}>
                {t('CLASS_SCHEDULE')}
            </Tab>,
            appointmentsMenu?.isAppointmentScheduleVisible && (
                <Tab
                    key="tab-2"
                    onClick={() => (window.location.href = `${WEBSITE}/appointments/appointment-reservation/${hostId}`)}
                >
                    {t('BOOK_APPOINTMENTS')}
                </Tab>
            ),
            data?.hasOnDemandAddon && isOnDemandHidden(hostId) && (
                <Tab
                    key="tab-3"
                    selected={selectedContent === 'store'}
                    onClick={() => (window.location.href = `${WEBSITE}/video/plugin/${hostId}`)}
                >
                    {!!data.numberOfCourses
                        ? hostId === 9272
                            ? 'Lekce a kurzy' // todo
                            : t('ONDEMAND_VIDEOS_AND_COURSES')
                        : t('VIDEO_LIBRARY')}
                </Tab>
            ),
        ],
        [
            appointmentsMenu?.isAppointmentScheduleVisible,
            data?.hasOnDemandAddon,
            data?.numberOfCourses,
            hostId,
            selectedContent,
            setSelectedContent,
            t,
        ]
    )

    if (tabs.filter(t => !!t).length <= 1) {
        return null
    }

    return <TabsContainer>{tabs}</TabsContainer>
}

const TabsContainer = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    & > * + * {
        margin-left: 2rem;
    }
`
