import { Heading, Subtitle } from '../styled'
import { SessionsPlugin } from './SessionsPlugin'
import { useLandingLang } from '../../hooks/useLandingLang'
import { useHomeContext } from '../HomeContext'

export const SchedulePlugin = () => {
    const t = useLandingLang()
    const { hostId } = useHomeContext()

    return (
        <>
            <Heading>{t('SCHEDULE')}</Heading>
            <Subtitle>{t('SCHEDULE_TIMEZONE_NOTE')}</Subtitle>

            <SessionsPlugin hostId={hostId} />
        </>
    )
}
