import { useEffect } from 'react'

export const useResizeObserver = (
    target: React.RefObject<HTMLElement>,
    handler: (bbox: ResizeObserverEntry) => void
) => {
    useEffect(() => {
        if (!window.ResizeObserver) {
            return
        }

        const resizeObserver = new ResizeObserver(entries => handler(entries[0]))

        if (target?.current) {
            resizeObserver.observe(target?.current)
        }

        return () => resizeObserver && resizeObserver.disconnect()
    }, [handler, target])
}
