import { createContext, useContext, useMemo, useState } from 'react'
import { Semester } from '../types'
import { StandaloneData } from '../api/endpoints/sessions'

export type HomeContextData = ReturnType<typeof useHomeContextData>

export const HomeContext = createContext<HomeContextData | null>(null)

export const useHomeContext = () => {
    const context = useContext(HomeContext)

    if (!context) {
        throw Error('useHomeContext hook can be used only inside HomeContextProvider')
    }

    return context
}

const useHomeContextData = () => {
    const [data, setData] = useState<StandaloneData | null>(null)
    const [selectedContent, setSelectedContent] = useState<any>('live')
    const [currency, setCurrency] = useState<any>(null)
    const [hostId, setHostId] = useState<any>(null)
    const [semesters, setSemesters] = useState<Semester[]>([])

    return useMemo(
        () => ({
            data,
            setData,
            selectedContent,
            setSelectedContent,
            currency,
            setCurrency,
            hostId,
            setHostId,
            semesters,
            setSemesters,
        }),
        [currency, data, hostId, selectedContent, semesters]
    )
}

export const HomeContextProvider = ({ children }: { children: React.ReactNode }) => {
    const value = useHomeContextData()

    return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}
