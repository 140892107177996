import { Suspense, lazy } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'

import { LandingPageThemeProvider } from '../styling/LandingPageThemeProvider'

import { Loader } from './components/Loader'
import { Home } from './home/Home'

const CheckIn = lazy(() => import('./checkin/CheckIn'))

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
})

export const App = () => (
    <QueryClientProvider client={queryClient}>
        <Router>
            <LandingPageThemeProvider>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route path="/u/:userUrl" exact component={Home} />
                        <ToastProvider placement={'top-left'} autoDismissTimeout={5000}>
                            <Route path="/u/:hostId/checkin/:locationId?" exact component={CheckIn} />
                        </ToastProvider>
                    </Switch>
                </Suspense>
            </LandingPageThemeProvider>
        </Router>
    </QueryClientProvider>
)
