import { useCallback } from 'react'
import t from '../i18n/en'

export type LandingTranslationKey = keyof typeof t
export type LandingTranslationFunction = (
    key: LandingTranslationKey,
    replacementValues?: (string | number)[],
    fallback?: string
) => string

export const useLandingLang = (): LandingTranslationFunction => {
    return useCallback((key: LandingTranslationKey) => t[key], [])
}
