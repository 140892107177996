import styled, { keyframes } from 'styled-components/macro'

type Props = {
    message?: string
}

const getInnerSpinners = () => {
    const rows = []

    for (let i = 0; i < 12; i++) {
        rows.push(<SpinnerDiv key={i} rotate={30 * i} delay={1.1 - i * 0.1} />)
    }

    return rows
}

export const Loader = ({ message }: Props) => (
    <Container>
        <Spinner>{getInnerSpinners()}</Spinner>
        {message && <Message>{message}</Message>}
    </Container>
)

const Container = styled.div`
    margin-left: -64px;
`

const Message = styled.div`
    text-align: center;
    margin: 2rem;
    padding-left: 64px;
`

const Spinner = styled.div`
    text-align: center;
    position: relative;
    margin: auto;
    width: 64px;
    height: 64px;
    padding: 50px;
`

const spinnerKeyframes = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`

const SpinnerDiv = styled.div<{ rotate: number; delay: number }>`
    transform-origin: 32px 32px;
    animation: ${spinnerKeyframes} 1.2s linear infinite;
    transform: rotate(${props => props.rotate}deg);
    animation-delay: -${props => props.delay}s;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 3px;
        left: 29px;
        width: 5px;
        height: 14px;
        border-radius: 20%;
        background-color: ${({ theme }) => theme.palette.purple};
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }
`
