import './fonts.css'
import { createGlobalStyle, css } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
${({ theme }) => css`
    * {
        box-sizing: border-box;
    }

    body {
        font-family: ${theme.typography.font.body.family};
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    :root {
        --momenceColorBackground: #ffffff;
        --momenceColorPrimary: ${({ theme }) => theme.palette.primary} !important;
        --momenceColorBlack: ${({ theme }) => theme.palette.secondary} !important;
    }

    .momence-host_schedule-session_list-item-price {
        display: none;
    }

    #momence-plugin-host-schedule .momence-host_schedule-session_list .momence-host_schedule-session-list-item-wrapper {
        grid-template-rows: auto 1fr;
    }
`}
`
