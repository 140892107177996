import { Heading } from '../styled'
import { useHomeContext } from '../HomeContext'
import { useLandingLang } from '../../hooks/useLandingLang'
import { useMemo } from 'react'
import styled from 'styled-components/macro'

export const Bio = () => {
    const t = useLandingLang()
    const { data } = useHomeContext()

    const paragraphs = useMemo(
        () =>
            ((data?.info?.bio || '') as string)
                .trim()
                .split('\n\n')
                .filter(str => str.length > 0),
        [data?.info.bio]
    )

    if (paragraphs.length === 0) {
        return null
    }

    return (
        <>
            <Heading>
                {t('BIO_HEADER')} {data?.info.name}
            </Heading>
            {paragraphs.map((paragraph, index) => (
                <Paragraph key={index}>{paragraph}</Paragraph>
            ))}
        </>
    )
}

const Paragraph = styled.p`
    line-height: 1.125rem;
    font-size: 0.875rem;
    opacity: 0.8;
`
