export const PURPLE = '#6b35ff'
export const PURPLE_LIGHT = '#8f66ff'
export const BLACK = '#000'

export const palette = {
    primary: PURPLE,
    secondary: BLACK,
    purple: PURPLE,
    purpleLight: PURPLE_LIGHT,
    // placeholders. will be computed from primary
    pageBackgroundColor: '#FFF',
    badgeBackgroundColor: '#FFF',
    badgeHoverBackgroundColor: '#FFF',
    buttonBackgroundColor: '#FFF',
    buttonHoverBackgroundColor: '#FFF',
}
