const t = {
    PURCHASE_OPTIONS: 'Purchase Options',
    SCHEDULE: 'Schedule',
    SCHEDULE_TIMEZONE_NOTE: '*All times are shown in your timezone',
    PURCHASE: 'Purchase',

    CLASS_SCHEDULE: 'Class Schedule',
    BOOK_APPOINTMENTS: 'Book Appointments',
    ONDEMAND_VIDEOS_AND_COURSES: 'On-Demand Videos and Courses',
    VIDEO_LIBRARY: 'Video Library',
    BIO_HEADER: 'About',
    SIGN_UP: 'Sign Up',
    SHOW_MORE: 'Show more',
    HIDE: 'Hide',
}

export default t
