export const SIZE_NUMBERS = {
    xs: 600,
    sm: 800,
    md: 1000,
    lg: 1200,
    inboxLg: 1350,
    xlg: 1500,
}

export const SIZE = {
    xs: `${SIZE_NUMBERS.xs}px`,
    sm: `${SIZE_NUMBERS.sm}px`,
    md: `${SIZE_NUMBERS.md}px`,
    lg: `${SIZE_NUMBERS.lg}px`,
    xlg: `${SIZE_NUMBERS.xlg}px`,
}

// browser dont support range context queries, so we need to adjust this be small fraction (so when resolution is exact 768 for example, we need to adjust a bit so min and max will work)
const ADJUSTED_NUMBER = 0.02

export const ADJUSTED_SIZE = {
    xs: `${SIZE_NUMBERS.xs + ADJUSTED_NUMBER}px`,
    sm: `${SIZE_NUMBERS.sm + ADJUSTED_NUMBER}px`,
    md: `${SIZE_NUMBERS.md + ADJUSTED_NUMBER}px`,
    lg: `${SIZE_NUMBERS.lg + ADJUSTED_NUMBER}px`,
    xlg: `${SIZE_NUMBERS.xlg + ADJUSTED_NUMBER}px`,
}

// because we are using desktop first approach, we adding this number instead of subtracting
export const QUERY = {
    belowXs: `(max-width: ${SIZE.xs})`,
    aboveXs: `(min-width: ${ADJUSTED_SIZE.xs})`,
    belowSm: `(max-width: ${SIZE.sm})`,
    aboveSm: `(min-width: ${ADJUSTED_SIZE.sm})`,
    belowMd: `(max-width: ${SIZE.md})`,
    aboveMd: `(min-width: ${ADJUSTED_SIZE.md})`,
    belowLg: `(max-width: ${SIZE.lg})`,
    aboveLg: `(min-width: ${ADJUSTED_SIZE.lg})`,
    belowXlg: `(max-width: ${SIZE.xlg})`,
    aboveXlg: `(min-width: ${ADJUSTED_SIZE.xlg})`,
    landscape: `(orientation: landscape)`,
    portrait: `(orientation: portrait)`,
}
