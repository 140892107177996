export const BODY_FONT_FAMILY =
    'Inter, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,Roboto, Helvetica Neue, sans-serif'

export const HEADER_FONT_FAMILY =
    'Epilogue, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,Roboto, Helvetica Neue, sans-serif'

export const typography = {
    font: {
        body: {
            family: BODY_FONT_FAMILY,
        },
        header: {
            family: HEADER_FONT_FAMILY,
        },
    },
}
