import { useLocation } from 'react-router-dom'
import { WEBSITE } from '../../api'
import { Heading } from '../styled'
import { useHomeContext } from '../HomeContext'
import { useLandingLang } from '../../hooks/useLandingLang'
import styled from 'styled-components/macro'
import { QUERY } from '../../../styling/contants'
import { ShowMore } from './ShowMore'

export const Semesters = () => {
    const location = useLocation()
    const { data, semesters } = useHomeContext()
    const t = useLandingLang()

    const getQueryParam = (name: string) => {
        return new URLSearchParams(location.search).get(name)
    }

    if (semesters.length === 0) {
        return null
    }

    return (
        <>
            <Heading>{data?.wordingCustomizations?.semesterTranslationKey ?? 'Semesters'}</Heading>
            {semesters.map(semester => {
                return (
                    <Container key={semester.id}>
                        <LeftSide>
                            <Title>{semester.title}</Title>
                            <ShowMore>{semester.level}</ShowMore>
                        </LeftSide>

                        <Button
                            onClick={() =>
                                (window.location.href = `${WEBSITE}/s/${semester.id}${
                                    getQueryParam('ref') ? `?ref=${getQueryParam('ref')}` : ''
                                }`)
                            }
                        >
                            {t('SIGN_UP')}
                        </Button>
                    </Container>
                )
            })}
        </>
    )
}

// These styles try to match the look of sessions plugin which is displayed directly below this semesters box
// Hence the occasional mismatch of px and rem

export const Container = styled.div`
    --momence-grey-200: #eaebeb;
    border: 1px solid var(--momence-grey-200);
    padding: 1.25rem 1.5rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media ${QUERY.belowSm} {
        flex-direction: column;
        align-items: stretch;
    }
    & + & {
        margin-top: 1rem;
    }
`

export const LeftSide = styled.div`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
    @media ${QUERY.belowSm} {
        margin-right: 0rem;
        margin-bottom: 1.5rem;
    }
`

export const Title = styled.h3`
    font-size: 24px;
    margin: 0;
    margin-bottom: 0.75rem;
    @media ${QUERY.belowSm} {
        font-size: 1rem;
    }
    font-weight: 500;
`

export const Button = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    height: 40px;
    border-radius: 0.5rem;
    font-size: 14px;
    line-height: 0.75rem;
    font-weight: 500;

    outline: none;
    border: none;
    cursor: pointer;

    transition: all 0.2s ease-out;

    color: #fff;
    background-color: ${({ theme }) => theme.palette.buttonBackgroundColor};
    &:hover {
        background-color: ${({ theme }) => theme.palette.buttonHoverBackgroundColor};
    }
`
