import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'
import { RouteComponentProps } from 'react-router-dom'

import { QUERY } from '../../styling/contants'

import { API_LATEST_STANDALONE, getLatestStandalone } from '../api/endpoints/sessions'
import { useCheckHostWebsiteRedirect } from '../hooks/useCheckHostWebsiteRedirect'

import { HomeContextProvider, useHomeContext } from './HomeContext'
import { Bio } from './components/Bio'
import { Header } from './components/Header'
import { LinkTabs } from './components/LinkTabs'
import { MembershipsPromo } from './components/MembershipsPromo'
import { SchedulePlugin } from './components/SchedulePlugin'
import { Semesters } from './components/Semesters'
import { formatEventSchedule } from './dataFormatter'

export const Home = (props: RouteComponentProps<{ userUrl: string }>) => {
    return (
        <HomeContextProvider>
            <HomeInner {...props} />
        </HomeContextProvider>
    )
}

const HomeInner = (props: RouteComponentProps<{ userUrl: string }>) => {
    const hostUrl = props.match.params.userUrl
    const dt = new Date()
    const tz = dt.getTimezoneOffset()

    const { data, setData, setCurrency, hostId, setHostId, setSemesters } = useHomeContext()

    const timezoneOffset = (tz / 60) * -1

    useQuery([API_LATEST_STANDALONE], () => getLatestStandalone(hostUrl as string, timezoneOffset), {
        onSuccess: rawData => {
            if (rawData) {
                setHostId(rawData.info.hostId)
                setCurrency(rawData.currency)
                const { semestersArray, data } = formatEventSchedule(rawData)
                setSemesters(semestersArray)
                setData(data)
            }
        },
    })

    const shouldRender = useCheckHostWebsiteRedirect(hostId)

    if (!data || !shouldRender) {
        return <div />
    }

    return (
        <>
            <Helmet>
                <title>{data.info.name} - Momence</title>
            </Helmet>

            <PageBackground>
                <PageContainer>
                    <Header />

                    <ContentBox>
                        <LinkTabs />
                        <Bio />
                        <MembershipsPromo />
                        <Semesters />
                        <SchedulePlugin />
                    </ContentBox>
                </PageContainer>
            </PageBackground>
        </>
    )
}

const PageBackground = styled.div`
    position: relative;
    min-height: 100%;
    background-color: ${({ theme }) => theme.palette.pageBackgroundColor};
`

const PageContainer = styled.div`
    margin: auto;
    max-width: 720px;
    padding: 3rem 2rem 2rem 2rem;
    @media ${QUERY.belowXs} {
        padding: 1rem 0 0 0;
    }
`

const ContentBox = styled.div`
    background: #fff;
    overflow: hidden;
    padding: 2rem;
    padding-bottom: 0;
    border-radius: 1rem;
    @media ${QUERY.belowXs} {
        padding: 1rem;
        border-radius: 0;
    }
`
