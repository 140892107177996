import Axios from 'axios'
import { API_ENDPOINT } from '..'

export const API_APPOINTMENTS_MENU = 'schedule/appointments-schedule'

export const getAppointmentsMenu = async (hostId: number) =>
    (
        await Axios.get<{ isAppointmentScheduleVisible: boolean }>(`${API_ENDPOINT}/schedule/appointments-schedule`, {
            params: {
                hostId,
            },
        })
    ).data
