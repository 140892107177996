import styled from 'styled-components/macro'
import { QUERY } from '../../styling/contants'

export const Heading = styled.h2`
    margin-top: 2rem;
    margin-bottom: 0.75rem;
    font-family: ${({ theme }) => theme.typography.font.header.family};
    font-weight: 500;
    font-size: 1.75rem;
    text-transform: capitalize;
    @media ${QUERY.belowSm} {
        font-size: 1.5rem;
    }
`

export const Subtitle = styled.div`
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    opacity: 0.8;
`
