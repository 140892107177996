import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { API_SESSIONS_PLUGIN, getSessionsPlugin } from '../../api/endpoints/sessions'
import styled from 'styled-components/macro'

type Params = {
    hostId: number
}

export const SessionsPlugin = ({ hostId }: Params) => {
    const { isFetched: isPluginFetched } = useQuery([hostId, API_SESSIONS_PLUGIN], () => getSessionsPlugin(hostId))

    useEffect(() => {
        if (hostId && isPluginFetched) {
            const script = document.createElement('script')

            script.src = 'https://momence.com/plugin/host-schedule/host-schedule.js'
            script.async = true
            script.setAttribute('host_id', hostId.toString())
            script.setAttribute('default_filter', 'show-all')

            document.body.appendChild(script)

            return () => {
                document.body.removeChild(script)
            }
        }
    }, [hostId, isPluginFetched])

    return (
        <Wrapper>
            <div id="momence-plugin-host-schedule" />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    @media (max-width: 430px) {
        margin: 0 -16px;
    }
    margin: 0 -32px;
`
