import axios from 'axios'
import { useEffect, useState } from 'react'

import { API_ENDPOINT } from '../api'

export const useCheckHostWebsiteRedirect = (hostId: number | string | null) => {
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {
        const check = async () => {
            if (!hostId) {
                return
            }

            const res = await axios.get<string | null>(`${API_ENDPOINT}/landing/redirect/${hostId}`)
            const hostWebsite = res.data

            if (hostWebsite) {
                window.location.href = hostWebsite
            } else {
                setShouldRender(true)
            }
        }

        check()
    }, [hostId])

    return shouldRender
}
