import { useQuery } from 'react-query'
import { WEBSITE } from '../../api'
import { API_SESSIONS_PLUGIN, getSessionsPlugin } from '../../api/endpoints/sessions'
import { Heading } from '../styled'
import { useLandingLang } from '../../hooks/useLandingLang'
import styled from 'styled-components/macro'
import { useHomeContext } from '../HomeContext'

export const MembershipsPromo = () => {
    const { data, hostId } = useHomeContext()
    const featuredMemberships = (data?.info.featuredMemberships ?? []) as { id: number; name: string }[]

    const { data: plugin } = useQuery([hostId, API_SESSIONS_PLUGIN], () => getSessionsPlugin(hostId))
    const t = useLandingLang()

    if (data?.info.featuredMemberships.length === 0) {
        return null
    }

    return (
        <>
            <Heading>{plugin?.promoMembershipsText ?? t('PURCHASE_OPTIONS')}</Heading>
            <TagGroup>
                {featuredMemberships.map(membership => (
                    <ButtonTag
                        onClick={() => window.open(`${WEBSITE}/m/${membership.id}`, '_blank')}
                        key={membership.id}
                    >
                        {membership.name}
                    </ButtonTag>
                ))}
            </TagGroup>
        </>
    )
}

export const TagGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
    }
`

export const ButtonTag = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: 500;

    outline: none;
    border: none;
    cursor: pointer;

    color: #333;
    &:hover {
        color: #000;
    }

    background-color: ${({ theme }) => theme.palette.badgeBackgroundColor};
    &:hover {
        background-color: ${({ theme }) => theme.palette.badgeHoverBackgroundColor};
    }
`
