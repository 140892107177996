import { useRef, useState } from 'react'
import { useLandingLang } from '../../hooks/useLandingLang'
import { Subtitle } from '../styled'
import styled from 'styled-components/macro'
import { useResizeObserver } from '../../hooks/useResizeObserver'

export const ShowMore = ({ children }: { children: React.ReactNode }) => {
    const t = useLandingLang()
    const [showButton, setShowButton] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const containerRef = useRef<HTMLDivElement>(null)

    useResizeObserver(containerRef, ({ contentRect }) => {
        // 32px === 2rem
        setShowButton(contentRect.height > 32)
    })

    if (!children) {
        return null
    }

    return (
        <>
            <Container expanded={expanded}>
                <Content ref={containerRef}>{children}</Content>
            </Container>
            {showButton && (
                <ShowMoreButton onClick={() => setExpanded(!expanded)}>
                    {t(expanded ? 'HIDE' : 'SHOW_MORE')}
                </ShowMoreButton>
            )}
        </>
    )
}

// These styles try to match the look of sessions plugin which is displayed directly below this semesters box
// Hence the occasional mismatch of px and rem

export const Container = styled.div<{ expanded?: boolean }>`
    margin-bottom: 1rem;
    overflow: hidden;
    max-height: ${({ expanded }) => (expanded === true ? '100rem' : '2rem')};
    transition: max-height 300ms ease-in-out;
`

export const Content = styled(Subtitle)`
    margin: 0;
`

export const ShowMoreButton = styled.div`
    color: ${({ theme }) => theme.palette.primary};
    font-size: 0.75rem;
    cursor: pointer;
`
