import styled, { css } from 'styled-components/macro'

export const Tab = ({
    selected,
    children,
    onClick,
}: {
    selected?: boolean
    children: React.ReactNode
    onClick: () => void
}) => {
    return (
        <TabContainer selected={selected} onClick={onClick}>
            {children}
        </TabContainer>
    )
}

const TabContainer = styled.div<{ selected?: boolean }>`
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    padding-bottom: 0.25rem;
    ${({ theme, selected }) =>
        selected &&
        css`
            color: ${theme.palette.primary};
            border-bottom: 2px solid ${theme.palette.primary};
        `}
`
