import { StandaloneData } from '../api/endpoints/sessions'

export const formatEventSchedule = (data: StandaloneData) => {
    return {
        semestersArray: data.sessions
            .filter(s => s.type === 'semester')
            .map(session => ({
                title: session.session_name,
                duration: session.duration_minutes,
                level: session.level,
                id: session.id,
                date_string: session.date_string,
                in_person: session.in_person,
                location: session.location,
            })),
        data,
    }
}
