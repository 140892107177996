// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./theme.d.ts" />

import styled, { DefaultTheme, ThemeProvider } from 'styled-components/macro'
import { GlobalStyles } from './global'
import { theme } from './theme'
import {
    API_LATEST_STANDALONE,
    API_SESSIONS_PLUGIN,
    getLatestStandalone,
    getSessionsPlugin,
} from '../app/api/endpoints/sessions'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import deepmerge from 'deepmerge'
import { parseToHsl, setLightness, setSaturation } from 'polished'
import { BLACK, PURPLE } from './palette'
import { useMemo } from 'react'

export const LandingPageThemeProvider = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation()
    const hostUrl = location.pathname.slice(3) // remove /u/
    const loadHostCustomizations = !!location.pathname.match(/^\/u\/[^\/]+\/?$/)

    const { data: hostData, isLoading: isLoadingHost } = useQuery(
        [API_LATEST_STANDALONE],
        () => getLatestStandalone(hostUrl, 0),
        { enabled: loadHostCustomizations }
    )

    const { data: customizations, isLoading: isLoadingCustomizations } = useQuery(
        [hostData?.info?.hostId, API_SESSIONS_PLUGIN],
        () => getSessionsPlugin(hostData?.info?.hostId as number),
        { enabled: !!hostData?.info && !!hostData?.info?.hostId }
    )

    const isLoading = isLoadingHost || isLoadingCustomizations

    const primaryColor = useMemo(() => customizations?.primaryColor ?? PURPLE, [customizations?.primaryColor])
    const secondaryColor = useMemo(() => customizations?.secondaryColor ?? BLACK, [customizations?.secondaryColor])
    const hsl = useMemo(() => parseToHsl(primaryColor), [primaryColor])

    const customizedPalette = useMemo(
        () => ({
            primary: primaryColor,
            secondary: secondaryColor,
            pageBackgroundColor: setSaturation(Math.min(hsl?.saturation, 0.2), setLightness(0.97, primaryColor)),
            badgeBackgroundColor: `hsl(${hsl?.hue}, ${hsl?.saturation * 100}%, 94%)`,
            badgeHoverBackgroundColor: `hsl(${hsl?.hue}, ${hsl?.saturation * 100}%, 90%)`,
            buttonBackgroundColor: `hsl(${hsl?.hue}, ${hsl?.saturation * 100}%, ${hsl?.lightness * 100}%)`,
            buttonHoverBackgroundColor: `hsl(${hsl?.hue}, ${hsl?.saturation * 100}%, ${hsl?.lightness * 93}%)`,
        }),
        [primaryColor, secondaryColor, hsl?.hue, hsl?.saturation, hsl?.lightness]
    )

    const customizedTheme = useMemo(() => deepmerge(theme, { palette: customizedPalette }), [customizedPalette])

    return (
        <ThemeProvider theme={customizedTheme as DefaultTheme}>
            <GlobalStyles />
            <LoadingWrapper isLoading={isLoading}>{children}</LoadingWrapper>
        </ThemeProvider>
    )
}

const LoadingWrapper = styled.div<{ isLoading?: boolean }>`
    visibility: ${({ isLoading }) => (isLoading ? 'hidden' : '')};
    height: 100%;
`
